// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
// global.$ = global.JQuery = JQuery;
import SimpleBar from "simplebar";
window.SimpleBar = SimpleBar;
import "bootstrap";
import '../stylesheets/light'


import "jquery-validation"; // requires jQuery



import "datatables.net";
import "datatables.net-bs5";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-bs5";
import "datatables.net-responsive";
import "datatables.net-select";
import "datatables.net-fixedheader";
